import React from "react"
import { useDirtyContext } from "../../../packages/editing/DirtyContext"
import { useParam } from "../../../packages/ssr/useParam"
import { useAccount } from "./client"
import { NewsSectionView } from "./sections/News"
import { Footer } from "./sections/Footer"
import { Slide } from "./Slide"
import { colors } from "./styles"
import { createContext } from "react"
import { GetAccountDto } from "./client"
import { EditableSections } from "../../../packages/editing/EditableSections"
import { ErrorView } from "./ErrorView"

export const AccountContext = createContext<GetAccountDto | null>(null)

export function AccountPage() {
    const slug = useParam("slug") as any
    const { data: account, error, loading } = useAccount(slug)

    // invalidate the page on editing
    useDirtyContext()

    if (loading) return <div></div>

    if (error) {
        return <ErrorView error={error} />
    }

    if (!account) return <></>

    return (
        <Slide
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                color: colors.foreground,
                fontSize: 20,
            }}
        >
            <AccountContext.Provider value={account}>
                <EditableSections sections={account.sections} />

                <NewsSectionView
                    section={{ title: "Related Articles" }}
                    filter={(a) => a.accounts.some((x) => x === account.id)}
                />
            </AccountContext.Provider>

            <Footer />
        </Slide>
    )
}
